import { Dispatch } from "redux";
import { ActionType } from "state/action-types";
import { updateMessageDataAction } from "./ChatEventActions";
import { Message } from "types/message";
import { getMessageResponse } from "graphQL/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";

export const toggleShowLogs = (messageId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.TOGGLE_MESSAGE_LOGS_VISIBILITY,
      payload: messageId,
    });
  };
};

export const slideAnswerVersion = (
  message: Message,
  questionId: string,
  answerId: string
) => {
  return async (dispatch: any) => {
    const hydrated_answer = message?.response?.answers?.find((answer) => answer.id === answerId);
    if (hydrated_answer) {
      message.response.answers = [...message.response.answers?.filter((answer)=>answer.id !== hydrated_answer.id)||[], message.response.response_component];
      message.response = {
        ...message.response,
        response_component: {...hydrated_answer, loading: false},
      };
    } else {
      const subPayload = {
        message_id: message?.id,
        question_version_id: questionId,
        answer_version_id: answerId,
      };
      message.response.answers = [...(message?.response?.answers?.filter((answer)=>answer.id !== message?.response?.response_component?.id)||[]), message.response.response_component];
      message.response.response_component.loading = true;
      dispatch(updateMessageDataAction(message));
      const result = await API.graphql<GraphQLQuery<{getMessageResponse:Message}>>(graphqlOperation(getMessageResponse, subPayload));
      if(result?.data?.getMessageResponse){
        message.response = {
            ...message.response,
            response_component: result.data.getMessageResponse?.response?.response_component,
            answers: [...message.response.answers, result.data.getMessageResponse?.response?.response_component],
          }
        ;
      }
    }
    dispatch(updateMessageDataAction(message));
  };
};
