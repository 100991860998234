import { gql } from "@apollo/client";

export const getMessageResponse = gql`
  query getMessageResponse($message_id: String!, $question_version_id: String!, $answer_version_id: String! ) {
    getMessageResponse(
      message_id: $message_id
      question_version_id: $question_version_id
      answer_version_id: $answer_version_id
    ) {
      id
      created_at
      sender {
        user_id
        email
        name
        chat_role
      }
      focus
      sources
      message_versions {
        id
        created_at
        user_message {
          author {
            role
            tool
            metadata
          }
          content {
            type
            message
            metadata
          }
          metadata
        }
        message_intent
        question_version_number
        sender {
          user_id
          email
          name
          chat_role
        }
      }
      response {
        id
        response_versions
        parent_message_details {
          id
          created_at
          user_message {
            author {
              role
              tool
              metadata
            }
            content {
              type
              message
              metadata
            }
            metadata
          }
          message_intent
          question_version_number
          sender {
            user_id
            email
            name
            chat_role
          }
        }
        response_component {
          id
          processing_status {
            state
            reason
          }
          answer_version_number
          created_at
          feedback {
            text
            reaction
          }
          fragments {
            id
            author {
              role
              tool
              metadata
            }
            recepient
            content
            answer_id
            sub_message_id
            created_at
            readout_title
            processing_status {
              state
              reason
            }
            deltas {
              delta_id
              delta
              delta_type
              debug_logs {
                debug_message
                debug_type
                debug_time
              }
              delta_data_type
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
`;
