import { ActionType } from "state/action-types";
import { Dispatch } from "redux";
import { getChats, getMessages } from "graphQL/queries";
import { API, graphqlOperation } from "aws-amplify";
import { alert, push } from "./customRouter";
import { sendMessage, newChat, closeChat, blockMessage } from "graphQL/mutations";
import { chatThread, getChatsResponse, newChatResponse, sendNewMessagePayload } from "types/chatv2";
import { GraphQLQuery } from '@aws-amplify/api';

import { chatAgents, Command, HistoryItemType } from "types/chat";
import { getHistoryItem } from "services/history";
import { getMessagesResponse, Message, MessageVersion } from "types/message";
import { Event } from "types/eventReciever";
import { getRefreshToken } from "makeRequest";
import { deleteAllChatHistory } from "services/answer";
import { getCitationService } from "services/chatConfigurationServices";
import { setExpandedAnswerComponentIndex } from "./CommonActions";
import { handlePopUpVisible, setIsTableSearchInputCleared, tableSliderData } from "./AnswerActions";
import { showAnswerOnSubscribedAnswer } from "./QuestionsBarActions";
import { messageInitializing, threadCreated } from "./ChatEventActions";
// Action to update chats list
export const updateChatThreads = (chats: chatThread[]) => ({
  type: ActionType.UPDATE_CHATS_THREADS,
  payload: chats,
});

export const updateChatHasMoreThreads = (has_more: boolean) => ({
  type: ActionType.UPDATE_HAS_MORE_CHAT_THREADS,
  payload: has_more,
});

// Action to update search term for left side chat panel threads list
export const searchQueryAction = (search:string)=>{
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionType.UPDATE_SEARCH_CHAT_QUERY,
      payload: search
    })
  };
}

// Action to update chat debug mode
export const updateChatDebugMode = (mode: boolean) => ({
  type: ActionType.UPDATE_CHAT_DEBUG_MODE,
  payload: mode,
});

export const updateQuestionHistoryVisibility = (mode: boolean) => ({
  type: ActionType.UPDATE_QUESTION_HISTORY_VISIBILITY,
  payload: mode,
});

export const scrollMessagesListToBottomAction = (payload: boolean) => {
  return {
    type: ActionType.SCROLL_MESSAGES_LIST_TO_BOTTOM,
    payload,
  };
};

export const setValue = (payload: string) => {
  return {
    type: ActionType.SET_VALUE,
    payload,
  };
}
export const toggleAnswerModalAction = (payload: boolean) => {
  return {
    type: ActionType.TOGGLE_ANSWER_MODAL,
    payload,
  };
}
export const focusChatInput = () => {
  return {
    type: ActionType.FOCUS_CHAT_INPUT
  }
}

export const setChatQueryStringAction = (payload: { q: string }) => {
  return {
    type: ActionType.SET_CHAT_INPUT_VALUE,
    payload,
  };
};
export const updateUserChatHistoryVisualByQuestion = (utterance?: string,subscribed?:boolean , subscribed_id?:string ) => {
  return {
      type: ActionType.UPDATE_USER_CHAT_HISTORY_VISUAL_BY_QUESTION,
      payload: {
          utterance: utterance ,
          subscribed:subscribed,
          subscribed_id:subscribed_id,
      }
  }
}

// Action to fetch chats list
export const getChatThreadsAction = (page = 1) => {
  return async (dispatch: Dispatch, getState: () => any) => {
    try {
      const { limit, chats , has_next_page:has_more_pages } = getState().chat;
      const subPayload = { page, limit , offset: chats.length };
      
      if(has_more_pages || page == 1){
        const response = await API.graphql<GraphQLQuery<getChatsResponse>>(graphqlOperation(getChats, subPayload));
        if(response.data){
          const {
            getChats: { chats: newChats, has_next_page },
          } = response.data;
    
          if (newChats) {
            const appendedChats = page === 1 ? [...newChats] : [...chats, ...newChats];
            
            dispatch(updateChatThreads(appendedChats));
            dispatch(updateChatHasMoreThreads(has_next_page));
          } else {
            dispatch(updateChatHasMoreThreads(false));
          }
        }
      }
      return true;
    } catch (err) {
      dispatch(updateChatHasMoreThreads(false));
      return dispatch(alert("Something went wrong while fetching chat threads"));
    }
  };
};
// Action to create a new chat thread
type createNewChatActionPayload = {
  topic: string;
  agent: string;
  focus?: string;
  sources?: string[];
  aliasData?: any;
  redirect?: boolean;
};
export const newChatAction = ({ topic, agent ,focus, sources, aliasData, redirect = true}: createNewChatActionPayload) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const payload = { topic, agent };
      const response = await API.graphql<GraphQLQuery<newChatResponse>>(graphqlOperation(newChat, payload));
      const data = response.data;
      const errors = response.errors;
      const id = data?.newChat?.id;
      if (id) {
        const messagePayload:sendNewMessagePayload = {
          chat_id: id,
          is_new_chat: true,
          sources: sources||[],
          focus: focus||"",
          message_intent: "question",
          user_message: {
            author: {
              role: "user",
              tool: "",
              metadata: "",
            },
            content:{
              type:"text",
              message:topic||"",
              metadata:""
            },
            metadata: aliasData? JSON.stringify(aliasData) : "",
          },
          message_id: "",
          question_id: "",
        };
        if(redirect){
          dispatch(resetChatThreadAction());
          dispatch(push(`/chat?chat_id=${id}&is_new_chat=true`));
        }
        dispatch(sendMessageAction(messagePayload));
        return true;
      } else {
        console.error("Error while creating message:", errors);
        dispatch(alert("Could not create new chat"));
      }
    } catch (errorData) {
      console.error("Unhandled promise rejection:", errorData);
      dispatch(alert("Error while creating new chat"));
    }
    return false;
  };
};

export const blockChatMessageUpdatesAction = (payloadData: {chat_id: string, message_id: string,answer_id:string,question_id:string}) => {
  return async (dispatch: Dispatch) => {
    try {
      // console.log("blocked",payloadData)
        await API.graphql(graphqlOperation(blockMessage, payloadData));
    } catch (error) {
      dispatch(alert("Something went wrong in chat to stop generating"));
    }
  }
}

// Action to send a message
export const sendMessageAction = (messagePayload: sendNewMessagePayload) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const additionalHeaders = {
          "refresh_token": await getRefreshToken(),
        };
      const response = await API.graphql<GraphQLQuery<{sendMessage: Event}>>(
        graphqlOperation(sendMessage, messagePayload)
        ,additionalHeaders
      );
      const { data, errors } = response;
      if (data && !errors) {
        console.log("Message sent in thread" ,data);
        if(data.sendMessage){
          dispatch(threadCreated(data.sendMessage));
          dispatch(messageInitializing(data.sendMessage));
        }
        return true;
      } else {
        console.error("Error while sending message in thread:", errors);
        dispatch(alert("Could not send message in thread"));
      }
    } catch (error) {
      console.error("Unhandled promise rejection in sending message:", error);
      dispatch(alert("Error Occured while sending message in thread"));
    }
    return false;
  };
};

export const resetChatThreadAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.SET_CHAT_THREAD_MESSAGES,
      payload: []
    })
    dispatch({
      type: ActionType.SET_CURRENT_CHAT_THREAD,
      payload: null
    })
  }
}

export const updateCurrentThreadMessages = (chatThreadId: string|null, page:number) => {
  return async (dispatch: Dispatch<any>,getState:()=>any) => {
    try {
      const limit = 5;
      // cleaning up previous messages
      if(page == 1){
        dispatch(resetChatThreadAction());
      }
      if(chatThreadId){
        const subPayload = {
          chatId: chatThreadId,
          limit,
          page:!!page ? page : 1,
          offset: getState().chat.messages.length,
          sort: -1,
        };
        const result = await API.graphql<GraphQLQuery<getMessagesResponse>>(graphqlOperation(getMessages, subPayload));
        // in case response chat id doesn't match current id
        const current_chat_id = new URLSearchParams(window.location.search)?.get("chat_id")||"";
        if(current_chat_id !== result.data?.getMessages.chat_details.id) {
          return dispatch(updateCurrentThreadMessages(current_chat_id,0));
        }
        if(result?.data?.getMessages?.messages?.length === 0 ) return true
        // cleaning up previous messages
        if((current_chat_id !== getState().chat.current_thread_details?.id)){
          dispatch(resetChatThreadAction());
        }
        if(result?.data){
            // const response = sample_message
            const response = result.data.getMessages;
            const thread_agent = response.chat_details.agent;

            // showing logs from progress_assistant if there is no answer yet
            if(response.messages.length > 0){
              const last_message:Message = response.messages[0];
              if(
                last_message?.response?.response_component?.processing_status?.state!== "done" &&
                last_message?.response?.response_component?.processing_status?.state!== "blocked" &&
                last_message?.response?.response_component?.processing_status?.state!== "failure"
              ){
                last_message.showLogs = true;
              }
            }
            // setting active agent of current chat thread
            dispatch(setLatestMessageParameters(thread_agent,response.messages));
            // updating messages of current thread
            dispatch({
              type: ActionType.SET_CHAT_THREAD_MESSAGES,
              payload: page == 0 ? response?.messages||[] : [ ...getState()?.chat.messages,...response?.messages||[]]
            });
            // setting into current_thread_details
            dispatch({
              type: ActionType.SET_CURRENT_CHAT_THREAD,
              payload: response.chat_details
            });
            // updating has more messages of current thread for pagination
            return dispatch({
              type: ActionType.UPDATE_HAS_MORE_MESSAGES,
              payload: response.has_next_page
            });
          }
      }
    } catch (error) {
      // cleaning up previous messages
      if((chatThreadId !== getState().chat.current_thread_details?.id)||page == 1){
        dispatch(resetChatThreadAction());
      }
      console.error("Error while fetching messages",error);
      return dispatch(alert("Error while fetching messages"));
    }
  };
}

export const addPrimary_fields = (fieldsVlaue: any) => {
  // setting primary fields data into userHistory module of redux
  return {
    type: ActionType.ADD_PRIMARY_FIELDS_DATA,
    payload: fieldsVlaue,
  };
};
export const updateHistoryItemAction = (payload: {
  items: HistoryItemType[];
}) => {
  // hydrating visuals as cache for chats
  return {
    type: ActionType.UPDATE_HISTORY_ITEM,
    payload,
  };
};

export const getHistoryItemByUtteranceIdAction = (payload: { utteranceId: string }) => {
  return async (dispatch: Dispatch, getState: () => any) => {
    try {
      const cache_visuals = getState().chat.historyItems;
      const utteranceVisual = cache_visuals.find(
        (item:any) => item.utteranceId === payload.utteranceId
      );
      if(utteranceVisual) {
        const primary_fields_Values = utteranceVisual.primary_fields_Values;
        dispatch(addPrimary_fields(primary_fields_Values));
      } else {
        const { body, status }: any = await getHistoryItem(payload.utteranceId);
        if (status == 200) {
          const primary_fields_Values =
            body?.up_response?.data.output_hints?.primary_fields;
          dispatch(addPrimary_fields(primary_fields_Values));

          const historyItem = {
            utteranceId: payload.utteranceId,
            visual: body.visual,
            utterance: body.visual.title,
            subscribed: body.subscribed,
            subscribed_id: body.subscribed_id,
            is_starred: body.is_starred,
            thumbnail: body.visual.thumbnail,
            compute_route: body.compute_route,
            job_id: body.job_id,
            pdf_status: body.pdf_status,
            primary_fields_Values: primary_fields_Values,
          };

          return dispatch(updateHistoryItemAction({ items: [historyItem] }));
        }else{
          return dispatch(alert(body.error||"Something went wrong while fetching history item"));
        }
      }
    } catch (e) {
      console.error(e);
      return dispatch(alert());
    }
  };
};
export const setCloseChatAction = (payload: { chatId: string }) => {
  return async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();
    try {
        await API.graphql(
        graphqlOperation(closeChat, { chat_id: payload.chatId })
      );
      const current_chat_id = state.chat?.current_thread_details?.id;
      if(current_chat_id == payload.chatId) {
        dispatch(push("/chat"));
      }
      const filterRecords = state.chat.chats.filter((item:any) => item.id !== payload.chatId);
      dispatch(updateChatThreads(filterRecords));
    } catch (err) {
      console.log(err);
      dispatch(alert("Something went wrong while closing chat"));
    }
  };
};

export const setLatestMessageParameters = (thread_agent:string,messages:Message[])=>{
  return async (dispatch: Dispatch, getState: () => any) => {
    const agent = getState().agent?.agents?.find((agent:chatAgents)=>agent.agent_name === thread_agent);
    if(agent){
      dispatch({ 
        type: ActionType.SET_ACTIVE_AGENT,
        payload: agent
      })
      if(messages.length > 0){
        const last_message:Message = messages[0];
        const last_message_focus:Command = agent.config.commands.find((command:Command)=>command.id === last_message.focus);
        const last_message_sources:string[] = last_message.sources;
        // setting focus and sources of latest message of the thread
        dispatch({
          type: ActionType.SET_SELECTED_COMMAND,
          payload: last_message_focus
        })
        dispatch({
          type: "SET_SELECTED_SOURCES",
          payload:  last_message_sources.filter((selectedSourceId: string) => {
            const sourceExists = agent.config.dataSources.some((source: Command) => source.id === selectedSourceId);
            return sourceExists;
          })
        })
      }
    }
  }
}

export const onDeleteMethodAction = () => {
  return async (dispatch: Dispatch<any>) => {
    try{
      const response = await deleteAllChatHistory();

      if(response.status === 200 && response.body.status === true){
        dispatch(alert(`${response.body.message}`,  { position: "bottom-left" },"success"));
        dispatch(updateChatThreads([]));
        dispatch(updateChatHasMoreThreads(false));
        return dispatch(push("/chat"));
      }else{
        return dispatch(alert(response.body.error));
      }
    }catch(error){
      return dispatch(alert("something went wrong to delete all chat history"))
    }
  }
}

export const getCitationData = (href: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setSourceRecordsLoading(true));
    try {
      dispatch(setSourceRecords({}));
        const response = await getCitationService(href);
        if (response.status === 200) {
          dispatch(setCurrentCitationHref(href));
          dispatch(setCitationHref(href));
          dispatch(setSourceRecords(response.body.records));
          dispatch(setSourceRecordsLoading(false));
          return response;
        } else {
          dispatch(setSourceRecordsLoading(false));
          dispatch(setSourceRecords({}));
          return dispatch(alert(response.body.error));
        }
    } catch (error) {
      dispatch(setSourceRecordsLoading(false));
      dispatch(setSourceRecords({}));
      return dispatch(alert("Something went wrong while fetching citation data"));
    }
  }
}

export const setSourceRecordsLoading = (payload: boolean) => {
  return {
    type: ActionType.SET_IS_SOURCE_RECORDS_LOADING,
    payload,
  };
};

export const setSourceRecords = (payload: any) => {
  return {
    type: ActionType.SET_SOURCE_RECORDS,
    payload,
  };
};

export const setCitationHref = (payload: string) => {
  return {
    type: ActionType.SET_CITATION_HREF,
    payload,
  };
};

export const setSourceRecordsPosition = (payload: {
  isRight: boolean;
  isBottom: boolean;
  top?: number;
  left?: number;
}) => {
  return {
    type: ActionType.SET_SOURCE_RECORDS_POSITION,
    payload,
  };
};

export const setCurrentCitationHref = (payload: string) => {
  return {
    type: ActionType.SET_CURRENT_CITATION_HREF,
    payload,
  };
};

export const askQuestionInNewChatAction = (payload: { topic: string, redirect: boolean }) => {
  return async (dispatch: Dispatch<any>,getState: () => any  ) => {
    const selectedAgent = getState().agent?.selected_agent;
    const selectedCommand = getState().agent?.selectedCommand;
    const selectedSources = getState().agent?.selected_sources;
    if(payload.redirect){ 
      dispatch(newChatAction({
        agent: selectedAgent?.agent_name,
        focus: selectedCommand?.id,
        sources: selectedSources,
        topic: payload.topic,
        redirect: payload.redirect
      }));
    }else{
      const chat_id = new URLSearchParams(window.location.search)?.get("chat_id")||"";
      if(chat_id){
        dispatch(push(`/chat?chat_id=${chat_id}`));
      }else{
        dispatch(push("/chat"));
      }
      dispatch(setExpandedAnswerComponentIndex(-1));
      dispatch(handlePopUpVisible(false));
      dispatch(toggleAnswerModalAction(false));
      dispatch(showAnswerOnSubscribedAnswer(false));
      dispatch(tableSliderData([]));
      dispatch(setIsTableSearchInputCleared(true))
      setTimeout(()=>{
        dispatch(setValue(payload.topic));
      },500)
    }
  }
}

export const regenerateAction = (payload: { chat_id: string , message: Message, message_version: MessageVersion }) => {
  return async (dispatch: Dispatch<any>) => {
    const messagePayload:sendNewMessagePayload = {
      chat_id: payload.chat_id,
      is_new_chat: false,
      sources: payload.message.sources||[],
      focus: payload.message.focus||"",
      message_intent: "question",
      user_message: payload.message_version.user_message,
      message_id: payload.message.id,
      question_id: payload.message_version.id,
    };
    return await dispatch(sendMessageAction(messagePayload));
  }
}