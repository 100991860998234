import { Auth } from "aws-amplify";
import { store as reduxStore } from "./state";
import { logOutAndClearData as signOutUser } from "state/action-creators";
import { alert } from "state/action-creators/customRouter";
const API_ROOT = process.env.REACT_APP_API_ROOT!;
const session_expired_msg = "Your session has expired. Please log in again to continue."
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ResponseSuccess<T> = {
  status: 200 | 201;
  body: T;
};


type ResponseFailure = {
  status: 400 | 500 | 401 | 409 | 404 | 403 | 204;
  body: {
    error: string;
    errorCode: number;
    success?: boolean;
    message: string;
    error_message?: string;
    msg?: string
  };
};

export type Response<T = {}> = Promise<ResponseSuccess<T> | ResponseFailure>;

function shouldLogoutUser() {
  const refreshTokenExpires = Number(localStorage.getItem('refreshTokenExpires'))??null;
  if (refreshTokenExpires) {
    const refreshTokenExpiresMs = new Date(refreshTokenExpires);
    const currentTime = new Date();
    if (currentTime > refreshTokenExpiresMs) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

async function getAccessToken(): Promise<string> {
  try {
    if(shouldLogoutUser()) {
      reduxStore.dispatch(signOutUser());
    }
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (error) {
    console.error("Error getting access token:", error);
    reduxStore.dispatch(signOutUser())
    reduxStore.dispatch(alert(session_expired_msg));
    throw error
  }
}

export async function getRefreshToken(): Promise<string> {
  try {
    const session = await Auth.currentSession();
    return session.getRefreshToken().getToken();
  } catch(error) {
    console.error("Error getting refresh token:", error);
    reduxStore.dispatch(signOutUser());
    reduxStore.dispatch(alert(session_expired_msg));
    throw error
  }
}

export async function get<T>(URI: string): Promise<T> {
  const accessToken = await getAccessToken();
  const refreshToken = await getRefreshToken();
  const response = await fetch(`${API_ROOT}${URI}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "refresh": `Bearer ${refreshToken}`
    },
    redirect: "follow",
    referrer: "no-referrer",
  });

  if(response.status === 401) {
    reduxStore.dispatch(signOutUser());
    reduxStore.dispatch(alert(session_expired_msg));
  }
  const result = {
    status: response.status,
    body: await response.json(),
  };
  return (result as any) as T;
}
export async function get_no_auth<T>(URI: string): Promise<T> {
  const response = await fetch(`${API_ROOT}${URI}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrer: "no-referrer",
  });

  const result = {
    status: response.status,
    body: await response.json(),
  };
  return (result as any) as T;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function download_file<T>(URI: string, data: any, filename:any, extension?: string){
  const accessToken = await getAccessToken();
  const refreshToken = await getRefreshToken();
  const response = await fetch(`${API_ROOT}${URI}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      Authorization: `Bearer ${accessToken}`,
      "refresh": `Bearer ${refreshToken}`
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data),
  });

  const blob = await response.blob();
  const newBlob = new Blob([blob]);

  const blobUrl = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  const fullFilename = extension? `${filename}.${extension}` : filename
  link.href = blobUrl;
  link.setAttribute('download', fullFilename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);

  // clean up Url
  window.URL.revokeObjectURL(blobUrl);

}

export async function postNew<T>(URI: string, data: any, visual_type:string): Promise<T> {

  const accessToken = await getAccessToken();
  const refreshToken = await getRefreshToken();
  const headers = new Headers()
  let body = JSON.stringify({});
  headers.append(`Authorization`, `Bearer ${accessToken}`)
  headers.append(`refresh`, `Bearer ${refreshToken}`)

  if(visual_type == 'table') {
    headers.append(`Content-Type`, `application/json`);
    body = data ? JSON.stringify(data) : '';
  } else {
    const isFormDataRequest = data instanceof FormData
    body = data.buffer;
    if(!isFormDataRequest) {
      headers.append(`Content-Type`, `application/binary`)
    }
  }

  const response = await fetch(`${API_ROOT}${URI}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: headers,
    redirect: "follow",
    referrer: "no-referrer",
    body,
  });


  if(response.status === 401) {
    reduxStore.dispatch(signOutUser());
    reduxStore.dispatch(alert(session_expired_msg));
  }

  const result = {
    status: response.status,
    body: await response.json(),
  };
  return (result as any) as T;
}

export async function post<T>(URI: string, data: any): Promise<T> {
  const accessToken = await getAccessToken();
  const isFormDataRequest = data instanceof FormData
  const body = isFormDataRequest? data : JSON.stringify(data)
  const headers = new Headers()
  headers.append(`Authorization`, `Bearer ${accessToken}`)
  headers.append(`refresh`, `Bearer ${await getRefreshToken()}`)
  if(!isFormDataRequest) {
    headers.append(`Content-Type`, `application/json`)
  }
  const response = await fetch(`${API_ROOT}${URI}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: headers,
    redirect: "follow",
    referrer: "no-referrer",
    body,
  });


  if(response.status === 401) {
    reduxStore.dispatch(signOutUser());
    reduxStore.dispatch(alert(session_expired_msg));
  }

  const result = {
    status: response.status,
    body: await response.json(),
  };
  return (result as any) as T;
}

export async function delete_req<T>(URI: string, data: any = {}): Promise<T> {
  const accessToken = await getAccessToken();
  const refreshToken = await getRefreshToken();
  const response = await fetch(`${API_ROOT}${URI}`, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "refresh": `Bearer ${refreshToken}`
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data),
  });

  if(response.status === 401) {
    reduxStore.dispatch(signOutUser());
    reduxStore.dispatch(alert(session_expired_msg));
  }

  const result = {
    status: response.status,
    body: await response.json(),
  };
  return (result as any) as T;
}

export async function patch<T>(URI: string, data: any = {}): Promise<T> {
  const accessToken = await getAccessToken();
  const refreshToken = await getRefreshToken();
  const response = await fetch(`${API_ROOT}${URI}`, {
    method: "PATCH",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
       "refresh": `Bearer ${refreshToken}`
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data),
  });

  if(response.status === 401) {
    reduxStore.dispatch(signOutUser());
    reduxStore.dispatch(alert(session_expired_msg));
  }

  const result = {
    status: response.status,
    body: await response.json(),
  };
  return (result as any) as T;
}

export async function put<T>(URI: string, data: any = {}): Promise<T> {
  const accessToken = await getAccessToken();
  const refreshToken = await getRefreshToken();
  const response = await fetch(`${API_ROOT}${URI}`, {
    method: "PUT",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "refresh": `Bearer ${refreshToken}`
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data),
  });

  if(response.status === 401) {
    reduxStore.dispatch(signOutUser());
    reduxStore.dispatch(alert(session_expired_msg));
  }

  const result = {
    status: response.status,
    body: await response.json(),
  };
  return (result as any) as T;
}

export async function getPdf<T>(pdf: string): Promise<T> {
  const accessToken = await getAccessToken();
  const refreshToken = await getRefreshToken();
  const response = await fetch(`${API_ROOT}/${pdf}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "refresh": `Bearer ${refreshToken}`
    },
  });
  const headers = response.headers
  const contentType = headers.get('content-type');
  if(response.status === 401) {
    reduxStore.dispatch(signOutUser());
    reduxStore.dispatch(alert(session_expired_msg));
  }
  const result = {
    status: response.status,
    body: contentType == "application/json"?await response.json():await response.blob(),
    headers: response.headers
  };
  return (result as any) as T;
}

export async function asyncToDownloadPdf<T>(utterance_id: any, utterance:string): Promise<T> {
  const accessToken = await getAccessToken();
  const url = `${API_ROOT}/download-pdf`;
  const filename = utterance !== "" ? utterance + '.pdf' : 'Report.pdf';
  return await downloadFile<T>(url, filename, accessToken, 'POST', { utterance_id });
}

export async function downloadReportExportOptions<T>(s3_path:string, question:string, exportType: string): Promise<T> {
  const accessToken = await getAccessToken();
  const url = `${API_ROOT}/download-exported-file`;
  const filename = question !== "" ? question + `.${exportType}` : `Report.${exportType}`;
  return await downloadFile<T>(url, filename, accessToken, 'POST', { s3_path });
}

export async function downloadReportDocx<T>(file_id:string): Promise<T> {
  const accessToken = await getAccessToken();
  const url = `${API_ROOT}/download-file-by-id?file_id=${file_id}`;
  const filename = file_id;
  return await downloadFile<T>(url, filename, accessToken, 'GET');
}

async function downloadFile<T>(url: string, filename: string, accessToken: string, method: string, additionalParams?: any): Promise<T> {
  const refreshToken = await getRefreshToken();
  const requestOptions: RequestInit = {
    method: method,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "refresh": `Bearer ${refreshToken}`
    },
    redirect: "follow",
    referrer: "no-referrer",
  };

  if (method === 'POST' && additionalParams) {
    requestOptions.body = JSON.stringify(additionalParams);
  }

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    throw new Error(`Error ${response.status}: ${response.statusText}`);
  }

  const fileBlob = await response.blob();
  const fileUrl = URL.createObjectURL(fileBlob);
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = filename;
  link.click();

  return fileBlob as any as T;
}
